import React, { useContext } from 'react'
import './ProductDisplay.css'
import star_icon from "../Assets/star_icon.png";
import star_dull_icon from "../Assets/star_dull_icon.png";
import { BuyContext } from '../../Context/BuyContext';

const ProductDisplay = (props) => {

    const {product} = props;
    const {addToCart} = useContext(BuyContext);
  return (
    <div className='productdisplay'>
      <div className="productdisplay-left">
        <div className="productdisplay-img-list">
            <img src={product.image} alt="" />
            <img src={product.image} alt="" />
            <img src={product.image} alt="" />
            <img src={product.image} alt="" />
        </div>
        <div className="productdisplay-img">
            <img className='productdisplay-main-img' src={product.image} alt="" />
        </div>
      </div>
      <div className="productdisplay-right">
        <h1>{product.name}</h1>
        <div className="productdisplay-right-stars">
            <img src={star_icon} alt="" />
            <img src={star_icon} alt="" />
            <img src={star_icon} alt="" />
            <img src={star_icon} alt="" />
            <img src={star_dull_icon} alt="" />
            <p>(122)</p>
        </div>
        <div className="productdisplay-right-prices">
            <div className="productdisplay-right-price-old">{product.old_price}</div>
            <div className="productdisplay-right-price-new">{product.new_price}</div>
        </div>
        <div className="productdisplay-right-description">
        Indulge in the rich flavors of authentic biryani with Daawat Biryani Basmati Rice. Sourced from the finest basmati grains, each 5 kg pack is a testament to quality and excellence. With its long, slender grains and distinct aroma, this basmati rice is tailor-made for creating the perfect biryani experience.
        </div>
        <div className="productdisplay-right-size">
            <h1>Select Size</h1>
            <div className="productdisplay-right-sizes">
                <div>1 KG</div>
                <div>5 KG</div>
                <div>10 KG</div>
                <div>15 KG</div>
                <div>25 KG</div>
            </div>
        </div>
        <button onClick={()=>{addToCart(product.id)}}>ADD TO CART</button>
        <p className='productdisplay-right-category'><span>Category :</span>Small , BasmatiRice, Rice</p>
        <p className='productdisplay-right-category'><span>Tags :</span>Old, Latest</p>
      </div>
    </div>
  )
}

export default ProductDisplay
