import React from 'react'
import './Details.css'
import edit_details from '../../Assets/edit_repo.svg'

const Details = () => {
  return (
    <div className="details">
        <div className="container_details">
          Profile
        </div>
          <div className="details_submit">
            <img className='details_img' src={edit_details}  />
            <div className="details_msg">Submit</div>
          </div>
    </div>
  )
}

export default Details