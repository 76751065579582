import p1_img from "./product_1.png";
import p2_img from "./product_2.png";
import p3_img from "./product_3.png";
import p4_img from "./product_4.png";
import p5_img from "./product_5.png";
import p6_img from "./product_6.png";
import p7_img from "./product_7.png";
import p8_img from "./product_8.png";
import p9_img from "./product_9.png";
import p10_img from "./product_10.png";
import p11_img from "./product_11.png";
import p12_img from "./product_12.png";
import p13_img from "./product_13.png";
import p14_img from "./product_14.png";
import p15_img from "./product_15.png";
import p16_img from "./product_16.png";
import p17_img from "./product_17.png";
import p18_img from "./product_18.png";
import p19_img from "./product_19.png";
import p20_img from "./product_20.png";
import p21_img from "./product_21.png";
import p22_img from "./product_22.png";
import p23_img from "./product_23.png";
import p24_img from "./product_24.png";
import p25_img from "./product_25.png";
import p26_img from "./product_26.png";
import p27_img from "./product_27.png";
import p28_img from "./product_28.png";
import p29_img from "./product_29.png";
import p30_img from "./product_30.png";
import p31_img from "./product_31.png";
import p32_img from "./product_32.png";
import p33_img from "./product_33.png";
import p34_img from "./product_34.png";
import p35_img from "./product_35.png";
import p36_img from "./product_36.png";

let all_product = [
  {
    id: 1,
    name: "Jeera Rice",
    category: "small",
    image: p1_img,
    new_price: 5000,
    old_price: 8000,
  },
  {
    id: 2,
    name: "Tibar",
    category: "small",
    image: p2_img,
    new_price: 8500,
    old_price: 12005,
  },
  {
    id: 3,
    name: "Godawari",
    category: "small",
    image: p3_img,
    new_price: 600,
    old_price: 1005,
  },
  {
    id: 4,
    name: "Maharshi",
    category: "small",
    image: p4_img,
    new_price: 1000,
    old_price: 1500,
  },
  {
    id: 5,
    name: "Dawat Rozana",
    category: "small",
    image: p5_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 6,
    name: "Sri Sri",
    category: "small",
    image: p6_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 7,
    name: "India gate - Basmati Rice Durbar",
    category: "small",
    image: p7_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 8,
    name: "Heritage - Basmati Rice",
    category: "small",
    image: p8_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 9,
    name: "Devaaya",
    category: "small",
    image: p9_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 10,
    name: "Dawat Traditional - Basmati Rice",
    category: "small",
    image: p10_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 11,
    name: "Dawat - Basmati Rice",
    category: "small",
    image: p11_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 12,
    name: "Charminar",
    category: "small",
    image: p12_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 13,
    name: "Befach Diet and Diabetic White Rice",
    category: "medium",
    image: p13_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 14,
    name:"Heritage",
    category: "medium",
    image: p14_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 15,
    name: "Heritage - Basmati Rice",
    category: "medium",
    image: p15_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 16,
    name: "Daivik",
    category: "medium",
    image: p16_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 17,
    name: "Rice villa",
    category: "medium",
    image: p17_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 18,
    name: "Havmi - Black rice",
    category: "medium",
    image: p18_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 19,
    name: "Trinetra - Golden Rice",
    category: "medium",
    image: p19_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 20,
    name: "Lal Qilla",
    category: "medium",
    image: p20_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 21,
    name: "Nupsila",
    category: "medium",
    image: p21_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 22,
    name: "Indian gate - Weight Watchers",
    category: "medium",
    image: p22_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 23,
    name: "Indian Gate - Super",
    category: "medium",
    image: p23_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 24,
    name: "Jainji",
    category: "medium",
    image: p24_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 25,
    name: "Super Watchers - Low GI Rice",
    category: "large",
    image: p25_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 26,
    name: "Karuppu Kavani raw",
    category: "large",
    image: p26_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 27,
    name: "Fortune - Super Basmati Rice",
    category: "large",
    image: p27_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 28,
    name:"Pansari",
    category: "large",
    image: p28_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 29,
    name: "Eat Well Stay Healthy",
    category: "large",
    image: p29_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 30,
    name: "Think 3",
    category: "large",
    image: p30_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 31,
    name: "Befach",
    category: "large",
    image: p31_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 32,
    name: "Indian Gate 12Min",
    category: "large",
    image: p32_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 33,
    name: "Gobindo Bhog Rice",
    category: "large",
    image: p33_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 34,
    name: "Unity - Pulav Rice",
    category: "large",
    image: p34_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 35,
    name: "Farm to Home",
    category: "large",
    image: p35_img,
    new_price: 850,
    old_price: 1205,
  },
  {
    id: 36,
    name: "Vamsi Krishna",
    category: "large",
    image: p36_img,
    new_price: 850,
    old_price: 1205,
  },
];

export default all_product;