import React, { useState } from 'react';
import './Addresses.css';
import Edit from '../../Assets/edit_repo.svg';

const Addresses = () => {
  const [containers, setContainers] = useState([]);
  const addContainer = () => {
    const newContainer = [];
    setContainers([...containers, newContainer]);
  };
  const addElementToContainer = (containerIndex) => {
    const newElement = <div key={containers[containerIndex].length}>New Element</div>;
    const updatedContainers = [...containers];
    updatedContainers[containerIndex] = [...updatedContainers[containerIndex], newElement];
    setContainers(updatedContainers);
  };

  return (
    <div className='addresses'>
      <div className="addresses_text">Addresses</div>
      {containers.map((container, index) => (
        <div className="box" key={index}>
          {container.map((element, elementIndex) => (
            <div className="box_text" key={elementIndex}>{element}</div>
          ))}
          <div className="box_add" onClick={() => addElementToContainer(index)}>
            <div className="addresses_svg">
              <img src={Edit} alt="" />
              <div className="text">Add Element</div>
            </div>
          </div>
        </div>
      ))}
      <div className="box_button" onClick={addContainer}>
        <div className="addresses_svg">
          <img src={Edit} alt="" />
          <div className="text">Add Container</div>
        </div>
      </div>
    </div>
  );
};

export default Addresses;
