import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Profile.css';
import img from '../Assets/edit.png';
import wrong from '../Assets/wrong.svg';
import userprofile from '../Assets/profileuser.svg';
import Orders from './Orders/Orders'

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [username, setUsername] = useState('');
  const [activeButton, setActiveButton] = useState('Profile'); 
  const [textPreview, setTextPreview] = useState('');

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('http://localhost:5000/user');
      const userData = response.data;
      setAvatar(userData.avatar);
      setUsername(userData.username);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleBackToMain = () => {
    setIsEditing(false);
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('avatar', avatar);
      formData.append('username', username);

      await axios.put('http://localhost:4000/user', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchUserData();
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    switch (buttonName) {
      case 'Profile':
        setTextPreview('Profile Preview Text');
        break;
      case 'Addresses':
        setTextPreview('Addresses Preview Text');
        break;
      case 'Orders':
        setTextPreview('Orders Preview Text');
        break;
      case 'Favorites':
        setTextPreview('Favorites Preview Text');
        break;
      case 'Settings':
        setTextPreview('Settings Preview Text');
      break;
      default:
        setTextPreview('');
        break;
    }
  };

  return (
    <div className="profile">
      <div className="container">
        <div className="profile-img">
          <img src={userprofile} alt="Profile" />
          <div className="container-text">{username}</div>
        </div>
        <div className="edit-info" onClick={handleEditClick}>
            <div className="edit-text">
              <img src={img} alt="" />
              Edit Info
            </div>
          </div>
      </div>
      {isEditing && (
        <div className="edit-container">
          <div className="back-to-main" onClick={handleBackToMain}>
            <img src={wrong} alt="" />
          </div>
          <div className="edit-section">
            <div className="avatar-section">
              <img src={avatar ? URL.createObjectURL(avatar) : userprofile} alt="Profile" />
              <div className="avatar-container">
                <div className="avatar-text">
                  <label htmlFor="avatarInput" className="avatar-label">
                    Edit Avatar
                  </label>
                  <input
                    type="file"
                    id="avatarInput"
                    accept="image/*"
                    onChange={handleAvatarChange}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
            <div className="username-section">
              <input
                className="name"
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="submit-btn" onClick={handleSubmit}>
                Submit
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="constainer_items_box">
        <div className="container_side">
          <div
            className={`side_button ${activeButton === 'Profile' ? 'active' : ''}`}
            onClick={() => handleButtonClick('Profile')}
          >
            Profile
          </div>
          <div
            className={`side_button ${activeButton === 'Addresses' ? 'active' : ''}`}
            onClick={() => handleButtonClick('Addresses')}
          >
            Addresses
          </div>
          <div
            className={`side_button ${activeButton === 'Orders' ? 'active' : ''}`}
            onClick={() => handleButtonClick('Orders')}
          >
            Orders
          </div>
          <div
            className={`side_button ${activeButton === 'Favorites' ? 'active' : ''}`}
            onClick={() => handleButtonClick('Favorites')}
          >
            Favorites
          </div>
          <div
            className={`side_button ${activeButton === 'Settings' ? 'active' : ''}`}
            onClick={() => handleButtonClick('Settings')}
          >
            Settings
          </div>
        </div>
        <div className="sidebox-item-preview">
          {textPreview}
        </div>
      </div>
        
    </div>
  );
};

export default Profile;
