
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Shop from './Pages/Shop';
import ShopCategory from './Pages/ShopCategory';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import LoginSignup from './Pages/LoginSignup';
import Footer from './Components/Footer/Footer';
import small_banner from './Components/Assets/banner_small.png'
import medium_banner from './Components/Assets/banner_medium.png'
import large_banner from './Components/Assets/banner_large.png'
import Profile from './Components/Profile/Profile';
import Orders from './Components/Profile/Orders/Orders';
import Addresses from './Components/Profile/Address/Addresses';
import Settings from './Components/Profile/Settings/Settings';
import Details from './Components/Profile/Details/Details';
import Fav from './Components/Profile/Fav/Fav'


function App() {
  return (
    <div>
      <BrowserRouter>
      <div className='browser-container'>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Shop/>}/>
          <Route path='/small' element={<ShopCategory banner={small_banner} category="small"/>}/>
          <Route path='/medium' element={<ShopCategory banner={medium_banner} category="medium"/>}/>
          <Route path='/large' element={<ShopCategory banner={large_banner} category="large"/>}/>
          <Route path="/product" element={<Product/>}>
            <Route path=':productId' element={<Product/>}/>
          </Route>
          <Route path='/cart' element={<Cart/>}/>
          <Route path='/login' element={<LoginSignup/>}/>
          <Route path='/profile' element={<Profile/>}/>
          <Route path='/details' element={<Details/>}/>
          <Route path='/settings' element={<Settings/>}/>
          <Route path='/orders' element={<Orders/>}/>
          <Route path='/addresses' element={<Addresses/>}/>
          <Route path='/fav' element={<Fav/>}/>

        </Routes>
        <Footer/>
      </div>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
