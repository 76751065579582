import React, { useState } from 'react';
import * as Components from './Components';
import google from '../Components/Assets/Google.svg';
import {loginService} from '../service/loginService'; 
import { registerService } from '../service/registerService';
import { paste } from '@testing-library/user-event/dist/paste';

function SignIn() {
    const [signIn, toggle] = useState(true);
    const [signinEmail , setSigninEmail] = useState('');
    const [signinPassword , setSigninPassword] = useState('');
    const [registerEmail , setRegisterEmail] = useState('');
    const [registerName , setRegisterName] = useState('');
    const [registerPassword , setRegisterPassword] = useState('');
    const [registerConPassword,setRegisterConPassword]=useState('');

    async function handleLoginClick(event){
        event.preventDefault();
        await loginService({email: signinEmail, password: signinPassword})
            .then((res)=>{
                console.log(res);
            })
            .catch((err)=>{
                alert(err.message);
            })
    }    
    async function handleRegisterClick(event){
        event.preventDefault();

        if(registerConPassword !== registerPassword)
        {
            alert('Password Dosent Match')
            return;
        }
        await registerService({name:registerName,email:registerEmail,password:registerPassword})
        .then((res)=>{
            console.log(res);
        })
        .catch((err)=>{
            alert(err.message);
        })

    }

    return (
        <Components.Container>
            <Components.SignUpContainer signinIn={signIn}>
                <Components.Form onSubmit={(e)=>handleRegisterClick(e)}>
                    <Components.Title>Create Account</Components.Title>
                    <Components.Input value={registerName} onChange={(e)=>setRegisterName(e.target.value)} type='text' placeholder='Name' />
                    <Components.Input value={registerEmail} onChange={(e)=>setRegisterEmail(e.target.value)} type='email' placeholder='Email' />
                    <Components.Input value={registerPassword} onChange={(e)=>setRegisterPassword(e.target.value)} type='password' placeholder='Password' />
                    <Components.Input value={registerConPassword} onChange={(e)=>setRegisterConPassword(e.target.value)}type='password' placeholder='Confirm Password' />
                    <Components.Button type='submit'>Sign Up</Components.Button>
                </Components.Form>
            </Components.SignUpContainer>

            <Components.SignInContainer signinIn={signIn}>
                <Components.Form onSubmit={(e)=>handleLoginClick(e)}>
                    <Components.Title>Sign in</Components.Title>
                    <Components.Input value={signinEmail} onChange={(e)=>setSigninEmail(e.target.value)} type='email' placeholder='Email' />
                    <Components.Input value={signinPassword} onChange={(e)=>setSigninPassword(e.target.value)} type='password' placeholder='Password' />
                    <Components.Anchor href='#'>Forgot your password?</Components.Anchor>
                    <Components.Button type='submit'>Sigin In</Components.Button>
                </Components.Form>
            </Components.SignInContainer>
            <Components.OverlayContainer signinIn={signIn}>
                <Components.Overlay signinIn={signIn}>
                    <Components.LeftOverlayPanel signinIn={signIn}>
                        <Components.Title>Welcome Back!</Components.Title>
                        <Components.Paragraph>
                            To keep connected with us please login with your personal info
                        </Components.Paragraph>
                        <Components.GhostButton onClick={() => toggle(true)}>Sign In</Components.GhostButton>
                    </Components.LeftOverlayPanel>

                    <Components.RightOverlayPanel signinIn={signIn}>
                        <Components.Title>Hello, Friend!</Components.Title>
                        <Components.Paragraph>
                            Enter Your personal details and start journey with us
                        </Components.Paragraph>
                        <Components.GhostButton onClick={() => toggle(false)}>Sign Up</Components.GhostButton>
                    </Components.RightOverlayPanel>
                </Components.Overlay>
            </Components.OverlayContainer>
        </Components.Container>
    );
}

export default SignIn;
