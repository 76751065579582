import React, { useContext, useRef, useState } from 'react'
import './Navbar.css'
import logo from '../Assets/logo.png'
import cart_icon from '../Assets/cart_icon.png'
import nav_dropdown from '../Assets/nav_dropdown.png'
import { Link } from 'react-router-dom'
import { BuyContext } from '../../Context/BuyContext'

const Navbar = () => {

    const [menu,setMenu] = useState("Buy");
    const {getTotalCartItems}= useContext(BuyContext);
    const menuRef = useRef();

    const dropdown_toggle = (e) => {
      menuRef.current.classList.toggle('nav-menu-visible');
      e.target.classList.toggle('open');
    }

  return (
    <div className='navbar'>
      <Link to='/' onClick={()=>{setMenu("Buy")}} className="nav-logo">
        <img src={logo} alt="" />
        <p>BmBastha</p>
      </Link>
      <img onClick={dropdown_toggle} className='nav-dropdown' src={nav_dropdown} alt="" />
      <ul ref={menuRef} className="nav-menu">
        <li onClick={()=>{setMenu("Buy")}}><Link to='/'>Buy</Link>{menu==="buy"?<hr/>:<></>}</li>
        <li onClick={()=>{setMenu("Small")}}><Link to='/small'>Small</Link>{menu==="Small"?<hr/>:<></>}</li>
        <li onClick={()=>{setMenu("Medium")}}><Link to="/medium">Medium</Link>{menu==="Medium"?<hr/>:<></>}</li>
        <li onClick={()=>{setMenu("Large")}}><Link to='/large'>Large</Link>{menu==="Large"?<hr/>:<></>}</li>
        <li onClick={()=>{setMenu("Profile")}}><Link to='/profile'>Profile</Link>{menu==="Profile"?<hr/>:<></>}</li>
      </ul>
      <div className="nav-login-cart">
        <Link to='/login'><button>Login</button></Link>
        <Link to='/cart'><img src={cart_icon} alt="" /></Link>
        <div className="nav-cart-count">{getTotalCartItems()}</div>
      </div>
    </div>
  )
}

export default Navbar
