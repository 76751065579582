import React from 'react'
import './DescriptionBox.css'

const DescriptionBox = () => {
  return (
    <div className='descriptionbox'>
      <div className="descriptionbox-navigator">
        <div className="descriptionbox-nav-box">Description</div>
        <div className="descriptionbox-nav-box fade">Reviews (122)</div>
      </div>
      <div className="descriptionbox-description">
        <p>
          BMBastha Sels is a revolutionary project aimed at simplifying the process of obtaining high-quality rice for households. 
          With our doorstep delivery service, customers can now enjoy the convenience of ordering premium rice bags without leaving the comfort of their homes.
          We understand the importance of quality and convenience in today's fast-paced world, which is why we've streamlined the entire process. 
          Through our user-friendly online platform or mobile app, customers can browse through a diverse selection of rice products, including our flagship Basmati rice,
          renowned for its exceptional taste and aroma.
        </p>
      </div>
    </div>
  )
}

export default DescriptionBox
