import p1_img from './product_1.png'
import p2_img from './product_2.png'
import p3_img from './product_3.png'
import p4_img from './product_4.png'

let data_product = [
  {
    id: 1,
    name: "Jeera Rice",
    image: p1_img,
    new_price: 5000,
    old_price: 8000,
  },
  {
    id: 2,
    name: "Tibar",
    image: p2_img,
    new_price: 8500,
    old_price: 12005,
  },
  {
    id: 3,
    name: "Godawari",
    image: p3_img,
    new_price: 60.0,
    old_price: 100.5,
  },
  {
    id: 4,
    name: "Maharshi",
    image: p4_img,
    new_price: 1000,
    old_price: 1500,
  },
];

export default data_product;
