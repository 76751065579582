import axios from 'axios';

const URL =  process.env.REACT_APP_URL;

export async function getHomeBannersService(){
    try{
        const result = await axios.get(`${URL}/api/explore/home/list/banner/active`);
        return result.data;
    }
    catch(err){
        return {code: -1, message: 'Unable to connect to our server. Try again.'}
    }
}