import React, { useState, useEffect } from 'react';
import left_arrow from '../Assets/left_arrow.svg';
import right_arrow from '../Assets/right_arrow.svg';
import './Carousel.css'; 
import { getHomeBannersService } from '../../service/homeService';

const Carousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [bannerList , setBannerList] = useState([]);
  const [isBannerListFetched , setIsBannerListFetched] = useState(false)


  const goToPreviousImage = () => {
    console.log("PREV INDEX PREVI = " + currentImageIndex)
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? bannerList.length - 1 : prevIndex - 1));
    console.log(bannerList);
  };

  const goToNextImage = () => {
    console.log("PREV INDEX NEXT = " + currentImageIndex)
    setCurrentImageIndex((prevIndex) => (prevIndex === bannerList.length - 1 ? 0 : prevIndex + 1));
    console.log(bannerList);
  };


  async function getHomeAciveBannerList(){
    if(bannerList.length == 0){
      await getHomeBannersService()
        .then((res)=>{
          if(res.code==1){
            setBannerList(res.data);
            setIsBannerListFetched(true);
          }
          else{
            alert('Faield to get list');
            setIsBannerListFetched(false);
          }
        })
      }
  }

  useEffect(()=>{
    getHomeAciveBannerList();
  },  [])

  useEffect(() => {
    const interval = setInterval(goToNextImage, 10000); 
    return () => clearInterval(interval);
  }, []);

  return (
    isBannerListFetched && bannerList.length > 0 && (
      <div className='carousel'>
        <img 
          src={bannerList[currentImageIndex]?.banner_image}
          alt={bannerList[currentImageIndex]?.title} 
          className="carousel-image"
        />
        <button onClick={goToPreviousImage} className='carousel-button left-arrow'>
          <img src={left_arrow} alt="Previous" className="arrow-icon" />
        </button>
        <button onClick={goToNextImage} className='carousel-button right-arrow'>
          <img src={right_arrow} alt="Next" className="arrow-icon" />
        </button>
      </div>
    )
  );
};

export default Carousel;
