import React from 'react'
import './Fav.css'
import edit from '../../Assets/edit_repo.svg'

const Fav = () => {
  return (
    <div className='fav'>
      <div className="fav_details">
        <img className='fav_img' src={edit} width="30px" height="30px" />
        Edit
      </div>
    </div>
  )
}

export default Fav
